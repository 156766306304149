import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_menu_button = _resolveComponent("ion-menu-button")
  const _component_ion_buttons = _resolveComponent("ion-buttons")
  const _component_ion_title = _resolveComponent("ion-title")
  const _component_ion_icon = _resolveComponent("ion-icon")
  const _component_ion_button = _resolveComponent("ion-button")
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")
  const _component_ion_header = _resolveComponent("ion-header")
  const _component_ion_tab_button = _resolveComponent("ion-tab-button")
  const _component_ion_tab_bar = _resolveComponent("ion-tab-bar")
  const _component_ion_tabs = _resolveComponent("ion-tabs")
  const _component_ion_page = _resolveComponent("ion-page")

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_header, { translucent: true }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_toolbar, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_buttons, { slot: "start" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_menu_button)
                ]),
                _: 1
              }),
              _createVNode(_component_ion_title, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$route.name), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_ion_button, {
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.goToHome())),
                color: "primary",
                fill: "clear",
                slot: "end",
                class: "ion-no-padding ion-margin-end"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_icon, { icon: "home" })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_tabs, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_tab_bar, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_tab_button, {
                tab: "main",
                href: "/main/home"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_icon, { icon: "home" })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_tab_button, {
                tab: "aree-tematiche",
                href: "/main/aree-tematiche"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_icon, { icon: "layers" })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_tab_button, {
                tab: "real-time",
                href: "/main/real-time"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_icon, { icon: "timer" })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_tab_button, {
                tab: "banca-dati",
                href: "/main/banca-dati"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_icon, { icon: "library" })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}